<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No Nota</label>
                    <input type="text" v-model="currentData.no_nota" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Invoice No <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.invoice_no" placeholder="" required disabled />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama" placeholder="" required disabled />
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group" style="margin-bottom: 0px!important; ">
                    <label>Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 1002;">
                      <datepicker class="picker" v-model="currentData.tanggal" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Type<span class="text-danger">*</span></label>
                    <vue-select :options="tipeList" v-model="currentData.tipe" placeholder="None" required />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>QTY <span class="text-danger">*</span></label>
                    <input type="number" v-model="currentData.qty" placeholder="" equired />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Note</label>
                    <textarea class="form-control" v-model="currentData.keterangan">
                    </textarea>
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="itemsmovement" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="number"]:focus {
  border-color: #1ba0e2;
  background: #fff;
}
</style>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editemployee",
  watch: {
    'currentData.qty': {
      handler(newValue) {
        if (this.currentData.tipe == 'IN' && newValue < 0) {
          this.currentData.qty = newValue * -1;
        } else if (this.currentData.tipe == 'OUT' && newValue > 0) {
          this.currentData.qty = newValue * -1;
        }
      }
    },
    'currentData.tipe': {
      handler(newValue) {
        if (newValue == 'IN' && this.currentData.qty < 0) {
          this.currentData.qty = this.currentData.qty * -1;
        } else if (newValue == 'OUT' && this.currentData.qty > 0) {
          this.currentData.qty = this.currentData.qty * -1;
        }
      }
    },
  },
  data() {
    return {
      filter: true,
      title: "Insert Stock",
      title1: "add new Stock",
      config: "",
      api: "",
      tipeList: ["IN", "ADJ", "OUT"],
      //data
      currentData: {
      },
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentDataInsert'));
    this.currentData.tanggal = new Date();
  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    convertDate(value) {
      const date = new Date(value);

      // Use built-in methods to get year, month, and day
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      formData.delete('tanggal');
      formData.append('tanggal', this.convertDate(this.currentData.tanggal));

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'items_movement_insert';
      } else {
        this.api = base_url + 'items_movement_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("itemsmovement");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
